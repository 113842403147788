/* eslint-disable import/no-unresolved, import/extensions */
import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin: 0 auto;

  a.disabled {
    pointer-events: none;
  }

  @media (${props => props.theme.bp.mobileM}) {
    max-width: 100vw;
  }
`;

const PaginationButton = styled.div`
  width: 3rem;
  height: 3rem;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: transform 0.2s;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

const PaginationPages = styled.div`
  color: var(--lightblue);
  margin: 0 2.4rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border-radius: 24px;

  @media (${props => props.theme.bp.mobileM}) {
    margin: 0 0.5rem;
  }
`;

const PaginationPage = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0.5rem;
  font-weight: bold;
  color: var(--white);
  transition: background 0.2s;

  &:hover {
    background: var(--orange);
    color: var(--white);
  }

  &.active {
    background: var(--orange);
    color: var(--white);
  }
`;

export const Pagination = ({
  page,
  pages,
}: {
  page: number;
  pages: number;
}) => (
  <StyledPagination>
    <Link className={page === 1 ? "disabled" : ""} to={`/blogs/${page - 1}`}>
      <PaginationButton>
        {/* prettier-ignore */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
      </PaginationButton>
    </Link>

    <PaginationPages>
      {new Array(pages).fill(0).map((_, i) => (
        <Link key={i} to={`/blogs/${i + 1}`}>
          <PaginationPage className={i + 1 === page ? "active" : ""}>
            {i + 1}
          </PaginationPage>
        </Link>
      ))}
    </PaginationPages>

    <Link
      className={page === pages ? "disabled" : ""}
      to={`/blogs/${page + 1}`}
    >
      <PaginationButton>
        {/* prettier-ignore */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
      </PaginationButton>
    </Link>
  </StyledPagination>
);
