import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import format from 'date-fns/format';
import { Spacer } from './styles/Spacer';

const PostTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  transition: color 0.3s;
`;

const ReadMore = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: var(--lightblue);
`;

const StyledPostCard = styled.div`
  &:hover {
    ${PostTitle}, ${ReadMore} {
      color: var(--orange);
    }
  }
`;

const PostInfo = styled.div`
  display: flex;
`;

const PostTag = styled.div`
  font-size: 14px;
  opacity: 0.5;
  padding-right: 5px;
`;

const PostDate = styled(PostTag)`
  &:before {
    content: '\\B7';
    padding-right: 5px;
  }
`;

const PostExcerpt = styled.div`
  line-height: 30px;
`;

const PostFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PostDuration = styled(PostTag)``;

export const PostCard = ({ title, date, tags, excerpt, slug, timeToRead }) => (
  <StyledPostCard>
    <Link to={`/blog/${slug}`}>
      <PostTitle>{title}</PostTitle>
    </Link>
    <Spacer h='16px' />
    <PostInfo>
      {tags.map(tag => (
        <PostTag key={tag}>{tag}</PostTag>
      ))}
      <PostDate>{format(new Date(date), 'MMM dd, yyyy')}</PostDate>
    </PostInfo>
    <Spacer h='16px' />
    <PostExcerpt>{excerpt}</PostExcerpt>
    <Spacer h='16px' />
    <PostFooter>
      <PostDuration>{timeToRead} Min</PostDuration>
      <Link to={`/blog/${slug}`}>
        <ReadMore>Read More</ReadMore>
      </Link>
    </PostFooter>
  </StyledPostCard>
);
