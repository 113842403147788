/* eslint-disable import/no-unresolved, import/extensions */
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container, HeaderSVG, MaxWidthContainer, SVGShapeContainer } from '../pages/projects';
import { SVGShapeHeader } from '../components/svgs';
import { Title } from '../components/styles/Title';
import { Spacer } from '../components/styles/Spacer';
import { PostCard } from '../components/PostCard';
import Box from '../components/Box';
import { Pagination } from '../components/Pagination';

const PostsContainer = styled.div`
  & > * {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
`;

const Blog = ({ data, pageContext }) => {
  const { page, pages } = pageContext;

  const {
    allMarkdownRemark: { edges: posts },
  } = data;

  return (
    <Layout>
      <SEO title='Blogs' />
      <Container>
        <HeaderSVG>
          <SVGShapeContainer>
            <SVGShapeHeader />
          </SVGShapeContainer>
        </HeaderSVG>
        <MaxWidthContainer>
          <Title>Blogs</Title>
          <Spacer h='32px' />
          <PostsContainer>
            {posts.map(({ node: { frontmatter: { title, tags, date }, fields: { excerpt, slug }, timeToRead } }) => (
              <PostCard key={slug} {...{ title, slug, excerpt, date, tags, timeToRead }} />
            ))}
          </PostsContainer>
          <Box height='24px' />
          <Pagination page={page} pages={pages} />
        </MaxWidthContainer>
      </Container>
    </Layout>
  );
};

export default Blog;

export const BlogQuery = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          timeToRead
          fields {
            slug
            excerpt
          }
          frontmatter {
            title
            date
            tags
          }
        }
      }
    }
  }
`;
